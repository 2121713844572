import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Role } from '../../models/30role.models';
import { CONSTAINT_UTILS } from '../../utils/constaint.utils';

@Injectable({
  providedIn: 'root'
})

export class RoleService {

  // Define API
  apiURL = CONSTAINT_UTILS.api.frontend_url;

  constructor(private http: HttpClient) { }

  /*========================================
    Begin CRUD Methods for consuming RESTful API
  =========================================*/

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  /**
   * HttpClient API get() method => Fetch roles list
   * @returns 
   */
  get(): Observable<Role> {
    return this.http.get<Role>(this.apiURL + '/roles')
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API post() method => Create Role
   * @param Role 
   * @returns 
   */
  add(Role: Role): Observable<Role> {
    return this.http.post<Role>(this.apiURL + '/roles', JSON.stringify(Role), this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API put() method => Update Role
   * @param id 
   * @param Role 
   * @returns 
   */
  update(role: any): Observable<Role> {
    return this.http.put<Role>(this.apiURL + '/roles', role)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
   * HttpClient API delete() method => Delete Role
   * @param id 
   * @returns 
   */
  delete(id: any) {
    return this.http.delete<Role>(this.apiURL + '/roles/' + id, this.httpOptions)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => Fetch Role
  * @param id 
  * @returns 
  */
  find(id: any): Observable<Role> {
    return this.http.get<Role>(this.apiURL + '/roles/' + id)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /**
  * HttpClient API get() method => paginate Role with start page = 1 and limit
  * @param id 
  * @returns 
  */
  paginate(page: Number, limit: Number, filter: any): Observable<Role> {
    return this.http.get<Role>(this.apiURL + '/roles/paginate?page=' + page + '&limit=' + limit)
      .pipe(
        retry(1),
        catchError(this.handleError)
      );
  }

  /*========================================
    Begin Custom Methods for RESTful API
  =========================================*/





  /**
   * Error handling 
   * @param error 
   * @returns 
   */
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}